import React from 'react'
import { css } from 'emotion'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Share from '../components/Share'
// import MapContainer from '../components/MapContainer'

const main = css({
  'section .box': {
    padding: '0'
  },

  '.bold': {
    fontWeight: 'bold',
    margin: '0 !important'
  },

  '.services, .products': {
    marginTop: '1em',

    '.inner-box': {
      padding: '1em 0',
      justifyContent: 'space-evenly'
    }
  },

  '.products': {
    color: '#fff',
    width: '100%',
    p: {
      background: '#937EB6'
    }
  },

  '.services': {
    color: '#000',
    width: '100%',
    p: {
      background: '#C7EBBC'
    }
  },

  '.column .button': {
    background: '#6039B5',
    margin: '0.2rem',
    color: '#fff',

    '&.service': {
      background: '#73CE57'
    }
  },

  '.social svg': {
    maxHeight: '1.3em',
    maxWidth: '1.3em',
    margin: '0 0.4rem',
    transition: 'all 0.3s ease',

    ':hover': {
      transform: 'scale(1.15)'
    }
  },

  '.details': {
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '0 0.3em'
  },

  '.location': {
    height: '40vh',

    '> div': {
      position: 'relative',
      width: '100%',
      height: '100%'
    }
  },

  figure: {
    display: 'flex',
    margin: '0 auto !important'
  },

  '.header': {
    padding: '0.5rem 0',
    borderBottom: '1px solid #eaeaea',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
  },

  'h1, h4': {
    color: '#6039b5',
    margin: '0.3em 0 !important'
  },

  '.left': {
    backgroundImage: 'linear-gradient(10deg,#937eb6 0%, #f9f9f9 50%,#c7ebbc 100%)',
    padding: '0.15em !important',
    height: '100%',

    '.left-content': {
      background: '#fff',
      height: '100%',
      padding: '0.5em 0 0 '
    }
  },

  '.right-container': {
    display: 'flex',

    '.right': {
      padding: '2em 1em 0 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
  },

  '.links': {
    marginTop: '0.5em',
    display: 'flex',

    'div': {
      width: '100%'
    }
  },

  '.bigger': {
    height: '1.3em',
    width: '1.3em'
  },

  '.detail div a': {
    display: 'block'
  },

  '@media all and (max-width: 450px)': {
    '.details': {
      height: '100%'
    },

    '.detail': {
      padding: '0.4rem 0'
    },

    '.left-content': {
      padding: '2em 0 0 0'
    },

    '.right': {
      padding: '0 0 2em 0 !important'
    },
  }

})

class MemberPage extends React.Component {

  render() {
    const member = this.props.pageContext.node;
    const { location } = member;
    const { maps_loc } = location;

    let lng = -84.0792189, lat = 9.9335647;
    if (maps_loc) {
      const arr = maps_loc.split(',');
      if (arr.length > 1)
        lat = parseFloat(arr[0]);
      lng = parseFloat(arr[1]);
    }

    const renderSP = () => {
      return member.categories.length && member.categories.includes('service') && member.categories.includes('product') ? <span>{'Servicios & Productos'}</span> : <span>{member.categories[0] === 'service' ? 'Servicios' : 'Productos'}</span>
    }

    const icons = {
      WhatsApp:
        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#009A01" d="M17.498 14.382c-.301-.15-1.767-.867-2.04-.966-.273-.101-.473-.15-.673.15-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.174-.3-.019-.465.13-.615.136-.135.301-.345.451-.523.146-.181.194-.301.297-.496.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.172-.015-.371-.015-.571-.015-.2 0-.523.074-.797.359-.273.3-1.045 1.02-1.045 2.475s1.07 2.865 1.219 3.075c.149.195 2.105 3.195 5.1 4.485.714.3 1.27.48 1.704.629.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345m-5.446 7.443h-.016c-1.77 0-3.524-.48-5.055-1.38l-.36-.214-3.75.975 1.005-3.645-.239-.375c-.99-1.576-1.516-3.391-1.516-5.26 0-5.445 4.455-9.885 9.942-9.885 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99-.004 5.444-4.46 9.885-9.935 9.885M20.52 3.449C18.24 1.245 15.24 0 12.045 0 5.463 0 .104 5.334.101 11.893c0 2.096.549 4.14 1.595 5.945L0 24l6.335-1.652c1.746.943 3.71 1.444 5.71 1.447h.006c6.585 0 11.946-5.336 11.949-11.896 0-3.176-1.24-6.165-3.495-8.411" /></svg>,
      Snapchat:
        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12.206.793c.99 0 4.347.276 5.93 3.821.529 1.193.403 3.219.299 4.847l-.003.06c-.012.18-.022.345-.03.51.075.045.203.09.401.09.3-.016.659-.12 1.033-.301.165-.088.344-.104.464-.104.182 0 .359.029.509.09.45.149.734.479.734.838.015.449-.39.839-1.213 1.168-.089.029-.209.075-.344.119-.45.135-1.139.36-1.333.81-.09.224-.061.524.12.868l.015.015c.06.136 1.526 3.475 4.791 4.014.255.044.435.27.42.509 0 .075-.015.149-.045.225-.24.569-1.273.988-3.146 1.271-.059.091-.12.375-.164.57-.029.179-.074.36-.134.553-.076.271-.27.405-.555.405h-.03c-.135 0-.313-.031-.538-.074-.36-.075-.765-.135-1.273-.135-.3 0-.599.015-.913.074-.6.104-1.123.464-1.723.884-.853.599-1.826 1.288-3.294 1.288-.06 0-.119-.015-.18-.015h-.149c-1.468 0-2.427-.675-3.279-1.288-.599-.42-1.107-.779-1.707-.884-.314-.045-.629-.074-.928-.074-.54 0-.958.089-1.272.149-.211.043-.391.074-.54.074-.374 0-.523-.224-.583-.42-.061-.192-.09-.389-.135-.567-.046-.181-.105-.494-.166-.57-1.918-.222-2.95-.642-3.189-1.226-.031-.063-.052-.15-.055-.225-.015-.243.165-.465.42-.509 3.264-.54 4.73-3.879 4.791-4.02l.016-.029c.18-.345.224-.645.119-.869-.195-.434-.884-.658-1.332-.809-.121-.029-.24-.074-.346-.119-1.107-.435-1.257-.93-1.197-1.273.09-.479.674-.793 1.168-.793.146 0 .27.029.383.074.42.194.789.3 1.104.3.234 0 .384-.06.465-.105l-.046-.569c-.098-1.626-.225-3.651.307-4.837C7.392 1.077 10.739.807 11.727.807l.419-.015h.06z" /></svg>,
      LinkedIn:
        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#0077B5" d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" /></svg>,
      Pinterest:
        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#BD081C" d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z" /></svg>,
      Youtube:
        <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#FF0000" className="a" d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z" /></svg>,
      Facebook:
        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#3B5998" d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" /></svg>,
      Twitter:
        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#1DA1F2" d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" /></svg>,
      Vimeo:
        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#1AB7EA" d="M23.977 6.416c-.105 2.338-1.739 5.543-4.894 9.609-3.268 4.247-6.026 6.37-8.29 6.37-1.409 0-2.578-1.294-3.553-3.881L5.322 11.4C4.603 8.816 3.834 7.522 3.01 7.522c-.179 0-.806.378-1.881 1.132L0 7.197c1.185-1.044 2.351-2.084 3.501-3.128C5.08 2.701 6.266 1.984 7.055 1.91c1.867-.18 3.016 1.1 3.447 3.838.465 2.953.789 4.789.971 5.507.539 2.45 1.131 3.674 1.776 3.674.502 0 1.256-.796 2.265-2.385 1.004-1.589 1.54-2.797 1.612-3.628.144-1.371-.395-2.061-1.614-2.061-.574 0-1.167.121-1.777.391 1.186-3.868 3.434-5.757 6.762-5.637 2.473.06 3.628 1.664 3.493 4.797l-.013.01z" /></svg>,
      Instagram:
        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#E4405F" d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" /></svg>,
      Website:
        <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#0000ff" d="M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zm-88 16H248.029c-21.313 0-32.08 25.861-16.971 40.971l31.984 31.987L67.515 364.485c-4.686 4.686-4.686 12.284 0 16.971l31.029 31.029c4.687 4.686 12.285 4.686 16.971 0l195.526-195.526 31.988 31.991C358.058 263.977 384 253.425 384 231.979V120c0-13.255-10.745-24-24-24z"></path></svg>,
      Location:
        <svg className="svg-icon" viewBox="0 0 20 20"><path fill="#c14646" d="M10,1.375c-3.17,0-5.75,2.548-5.75,5.682c0,6.685,5.259,11.276,5.483,11.469c0.152,0.132,0.382,0.132,0.534,0c0.224-0.193,5.481-4.784,5.483-11.469C15.75,3.923,13.171,1.375,10,1.375 M10,17.653c-1.064-1.024-4.929-5.127-4.929-10.596c0-2.68,2.212-4.861,4.929-4.861s4.929,2.181,4.929,4.861C14.927,12.518,11.063,16.627,10,17.653 M10,3.839c-1.815,0-3.286,1.47-3.286,3.286s1.47,3.286,3.286,3.286s3.286-1.47,3.286-3.286S11.815,3.839,10,3.839 M10,9.589c-1.359,0-2.464-1.105-2.464-2.464S8.641,4.661,10,4.661s2.464,1.105,2.464,2.464S11.359,9.589,10,9.589"></path></svg>,
      Member:
        <svg className="svg-icon" viewBox="0 0 20 20"><path fill="#c14646" d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"></path></svg>,
      Schedule:
        <svg className="svg-icon" viewBox="0 0 20 20"><path fill="#c14646" d="M10.25,2.375c-4.212,0-7.625,3.413-7.625,7.625s3.413,7.625,7.625,7.625s7.625-3.413,7.625-7.625S14.462,2.375,10.25,2.375M10.651,16.811v-0.403c0-0.221-0.181-0.401-0.401-0.401s-0.401,0.181-0.401,0.401v0.403c-3.443-0.201-6.208-2.966-6.409-6.409h0.404c0.22,0,0.401-0.181,0.401-0.401S4.063,9.599,3.843,9.599H3.439C3.64,6.155,6.405,3.391,9.849,3.19v0.403c0,0.22,0.181,0.401,0.401,0.401s0.401-0.181,0.401-0.401V3.19c3.443,0.201,6.208,2.965,6.409,6.409h-0.404c-0.22,0-0.4,0.181-0.4,0.401s0.181,0.401,0.4,0.401h0.404C16.859,13.845,14.095,16.609,10.651,16.811 M12.662,12.412c-0.156,0.156-0.409,0.159-0.568,0l-2.127-2.129C9.986,10.302,9.849,10.192,9.849,10V5.184c0-0.221,0.181-0.401,0.401-0.401s0.401,0.181,0.401,0.401v4.651l2.011,2.008C12.818,12.001,12.818,12.256,12.662,12.412"></path></svg>,
      Contact:
        <svg className="svg-icon" viewBox="0 0 20 20"><path fill="#c14646" d="M14.467,1.771H5.533c-0.258,0-0.47,0.211-0.47,0.47v15.516c0,0.414,0.504,0.634,0.802,0.331L10,13.955l4.136,4.133c0.241,0.241,0.802,0.169,0.802-0.331V2.241C14.938,1.982,14.726,1.771,14.467,1.771 M13.997,16.621l-3.665-3.662c-0.186-0.186-0.479-0.186-0.664,0l-3.666,3.662V2.711h7.994V16.621z"></path></svg>,
      Email:
        <svg className="svg-icon bigger" viewBox="0 0 20 20"><path fill="#521dc7" d="M17.388,4.751H2.613c-0.213,0-0.389,0.175-0.389,0.389v9.72c0,0.216,0.175,0.389,0.389,0.389h14.775c0.214,0,0.389-0.173,0.389-0.389v-9.72C17.776,4.926,17.602,4.751,17.388,4.751 M16.448,5.53L10,11.984L3.552,5.53H16.448zM3.002,6.081l3.921,3.925l-3.921,3.925V6.081z M3.56,14.471l3.914-3.916l2.253,2.253c0.153,0.153,0.395,0.153,0.548,0l2.253-2.253l3.913,3.916H3.56z M16.999,13.931l-3.921-3.925l3.921-3.925V13.931z"></path></svg>,
      Phone:
        <svg className="svg-icon" viewBox="0 0 20 20"><path fill="#521dc7" d="M14.911,1.295H5.09c-0.737,0-1.339,0.603-1.339,1.339v14.733c0,0.736,0.603,1.338,1.339,1.338h9.821c0.737,0,1.339-0.602,1.339-1.338V2.634C16.25,1.898,15.648,1.295,14.911,1.295 M15.357,17.367c0,0.24-0.205,0.445-0.446,0.445H5.09c-0.241,0-0.446-0.205-0.446-0.445v-0.893h10.714V17.367z M15.357,15.58H4.644V4.42h10.714V15.58z M15.357,3.527H4.644V2.634c0-0.241,0.205-0.446,0.446-0.446h9.821c0.241,0,0.446,0.206,0.446,0.446V3.527z"></path></svg>,
      Social:
        <svg className="svg-icon bigger" viewBox="0 0 20 20"><path fill="#c14646" d="M17.657,2.982H2.342c-0.234,0-0.425,0.191-0.425,0.426v10.21c0,0.234,0.191,0.426,0.425,0.426h3.404v2.553c0,0.397,0.48,0.547,0.725,0.302l2.889-2.854h8.298c0.234,0,0.426-0.191,0.426-0.426V3.408C18.083,3.174,17.892,2.982,17.657,2.982M17.232,13.192H9.185c-0.113,0-0.219,0.045-0.3,0.124l-2.289,2.262v-1.96c0-0.233-0.191-0.426-0.425-0.426H2.767V3.833h14.465V13.192z M10,7.237c-0.821,0-1.489,0.668-1.489,1.489c0,0.821,0.668,1.489,1.489,1.489c0.821,0,1.488-0.668,1.488-1.489C11.488,7.905,10.821,7.237,10,7.237 M10,9.364c-0.352,0-0.638-0.288-0.638-0.638c0-0.351,0.287-0.638,0.638-0.638c0.351,0,0.638,0.287,0.638,0.638C10.638,9.077,10.351,9.364,10,9.364 M14.254,7.237c-0.821,0-1.489,0.668-1.489,1.489c0,0.821,0.668,1.489,1.489,1.489s1.489-0.668,1.489-1.489C15.743,7.905,15.075,7.237,14.254,7.237 M14.254,9.364c-0.351,0-0.638-0.288-0.638-0.638c0-0.351,0.287-0.638,0.638-0.638c0.352,0,0.639,0.287,0.639,0.638C14.893,9.077,14.605,9.364,14.254,9.364 M5.746,7.237c-0.821,0-1.489,0.668-1.489,1.489c0,0.821,0.668,1.489,1.489,1.489c0.821,0,1.489-0.668,1.489-1.489C7.234,7.905,6.566,7.237,5.746,7.237 M5.746,9.364c-0.351,0-0.638-0.288-0.638-0.638c0-0.351,0.287-0.638,0.638-0.638c0.351,0,0.638,0.287,0.638,0.638C6.384,9.077,6.096,9.364,5.746,9.364"></path></svg>
    }

    return (
      <Layout>
        <section className={main}>
          <SEO
            isBlogPost={false}
            postData={{
              description: `¡Mira el perfil de ${member.business_name} en la Red Económica Feminista de Chicas al Frente!`,
              title: `${member.business_name} - REF CAF`,
              slug: `/empresarias/${member.business_name_lc}`
            }}
            postImage={member.logo || '/img/ref-logo.jpg'}
          />
          <div className="content">
            <header className="header">
              <figure className="image is-128x128">
                <img className="is-rounded" src={member.logo || 'https://res.cloudinary.com/chicasalfrente/image/upload/v1544307277/logo-default.png'} alt={`${member.business_name}-logo`} />
              </figure>
              {
                member.business_name.length <= 35 ? <h1 className="has-text-centered">{member.business_name.toUpperCase()}</h1>
                  : <h4 className="has-text-centered">{member.business_name.toUpperCase()}</h4>
              }
              <Share title={`¡Mira el perfil de ${member.business_name} en la Red Económica Feminista de Chicas al Frente!`}
                slug={`/red-economica-feminista/empresarias/${member.business_name_lc}`}
                className="notsticky" />
            </header>
            <div className="columns">
              <div className="column">
                <div className="section left">
                  <div className="left-content has-text-centered">
                    <div className="details">
                      <div className="detail">
                        {icons['Member']}
                        <span className="has-text-weight-bold is-size-5">Encargada: </span>
                        <span>{member.name}</span>
                      </div>

                      <div className="detail">
                        {icons['Schedule']}
                        <span className="has-text-weight-bold is-size-5">Horario: </span>
                        <span>{member.schedule}</span>
                      </div>

                      <div className="detail">
                        {icons['Contact']}
                        <span className="has-text-weight-bold is-size-5">Contacto Empresa</span>
                        <div>
                          <a href={`mailto:${member.email}`}>{icons['Email']} {member.email}</a>
                          {
                            member.business_phone && <span>{icons['Phone']} {member.business_phone}</span>
                          }
                        </div>
                      </div>

                      <div className="detail">
                        <div className="social">
                          {icons['Social']}
                          <span className="has-text-weight-bold is-size-5">Redes Sociales</span>
                          {
                            member.social && <div className="links">
                              {
                                Object.keys(member.social).map((link, i) =>
                                  member.social[link] &&
                                  <div key={i}>
                                    <a title={link} href={
                                      link === 'WhatsApp' ? `https://api.whatsapp.com/send?phone=506${member.social[link]}` :
                                        link === 'Instagram' ? !member.social[link].includes('instagram.com') ? `https://instagram.com/${member.social[link]}` : member.social[link] :
                                          member.social[link]
                                    }
                                      target="_blank" rel="noopener noreferrer">
                                      {icons[link]}{link === 'WhatsApp' ? member.social[link] : null}
                                    </a>
                                  </div>
                                )
                              }
                            </div>
                          }
                        </div>
                      </div>

                      <div className="detail">
                        {icons['Location']}
                        <span className="has-text-weight-bold is-size-5">Ubicación: </span>
                        <span>{`${member.location.province}. ${member.location.address}`}</span>
                      </div>
                    </div>
                    {/* <hr />
                    <div className="location">
                      <MapContainer className="map" markerName={member.business_name} lat={lat} lng={lng} />
                    </div> */}
                  </div>
                </div>

              </div>
              <div className="column is-three-fifths right-container">
                <section className="right">
                  <div className="detail">
                    <span className="has-text-weight-bold is-size-5">Categorías: </span>
                    {renderSP()}
                    <hr />
                  </div>

                  <div className="detail">
                    <h5 className="subtitle is-size-5">¿De qué se trata?</h5>
                    <p>{member.description}</p>
                    <br />
                  </div>

                  <div className="detail">
                    <h5 className="subtitle is-size-5">¿Considerás que tu emprendimiento es feminista? ¿Por qué?</h5>
                    <p>{member.about_fem}</p>
                    <br />
                  </div>

                  <div className="detail">
                    <h5 className="subtitle is-size-5">¿Apoyás a otras mujeres con tu emprendimiento? ¿De qué manera?</h5>
                    <p>{member.about_support}</p>
                    <br />
                  </div>

                  <div className="columns is-centered">
                    {
                      member.selectedProducts.length > 0 && <div className="column is-four-fifths">
                        <div className="products box has-text-centered">
                          <p className="has-text-centered bold">Productos</p>
                          <div className="inner-box is-flex">
                            {
                              member.selectedProducts.map((product, i) =>
                                <Link title={product} key={i} to='/red-economica-feminista/productosyservicios' state={{ selectedProduct: product }}>
                                  <span className='button'>{product}</span>
                                </Link>

                              )
                            }
                          </div>
                        </div>
                      </div>
                    }
                    {
                      member.selectedServices.length > 0 && <div className="column is-four-fifths">
                        <div className="services box has-text-centered">
                          <p className="has-text-centered bold">Servicios</p>
                          <div className="inner-box">
                            {
                              member.selectedServices.map((service, i) =>
                                <Link title={service} key={i} to='/red-economica-feminista/productosyservicios' state={{ selectedService: service }}>
                                  <span className='button service'>{service}</span>
                                </Link>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default MemberPage